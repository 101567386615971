<template>
  <div class="container"> 
    <div class="list-header">
      <van-icon @click="$router.back(-1)" class="back-top-icon" name="arrow-left" />
      <div class="header-title">{{ type === 'change' ? '更换设备' : '设备退租' }}</div>
      <div class="header-left">&nbsp;</div>
    </div>
    <div class="h85"></div>
    <img class="device-img" src="@/accesst/rent/device-icon.png" alt="" srcset="" />
    <div class="sub-title">{{ type === 'change' ? '更换原因' : '退租原因' }}&nbsp;
      <span class="span-red">
        <img style="width: .2rem" src="@/accesst/rent/xing.png" alt="" srcset="" />&nbsp;请至少上传一张图片,最多上传三张图片
      </span>
    </div>
    <div class="img-list">
      <div class="img-list-item" v-for="(img, i) in imgList" :key="i">        
        <van-icon @click="rmImg(i)" class="del-img" name="cross" />
        <img class="device-img" :src="img" alt="" srcset="" />
      </div>
      <div @click="uploadImg" v-show="imgList.length < 3" v-preventReClick class="img-up-btn">
        <img src="@/accesst/rent/puls.png" alt="" srcset="" />
      </div>
    </div>
    <div class="q-desc">
      <van-field
        v-model="form.returnReason"        
        autosize
        label="描述原因"
        type="textarea"
        :rows="2"
        maxlength="100"
        placeholder="请输入"
        show-word-limit
      />
    </div>
    <div class="sub-title">退货单号&nbsp;</div>
    <div class="express-tip">{{ defaultReturnAddress[0] || ' ' }}<br/>{{ defaultReturnAddress[1] || ' '}}：{{ defaultReturnAddress[2] || ' ' }}</div>
    <div class="express-info">
      <div class="express-info-cell" style="width: 2.5rem">
        <van-field v-model="form.returnExpressName" placeholder="请输入快递公司" maxlength="11"/>
      </div>
      <div class="express-info-cell" style="width: 3.85rem">
        <van-field v-model="form.returnExpressOrder" placeholder="请输入快递单号" maxlength="20"/>
      </div>
    </div>
    <div v-if="type === 'change'" class="sub-title">收货地址
      <!-- <div class="sub-title-tip">通讯录</div> -->
    </div>
    <div v-if="type === 'change'" class="user-info">
      <div class="user-info-line-1">
        <div class="user-info-line-1-1">
          <div class="u-label">姓名</div>
          <van-field v-model="form.consignee" label-width="0px" maxlength="11" placeholder="请输入您的姓名"/>
        </div>
        <div class="user-info-line-1-1">
          <div class="u-label">手机号</div>
          <van-field v-model="form.phone" label-width="0px" maxlength="11" placeholder="请输入您的姓名"/>
        </div>
      </div>
      <div class="user-info-line-2">
        <div class="u-label">详细地址</div>
        <van-field v-model="form.deliveryAddr" label-width="0px" maxlength="100" placeholder="请输入您的收货地址"/>        
      </div>
    </div>
    <div class="danger-tip">
      <img style="width: .24rem" src="@/accesst/rent/xing.png" alt="" srcset="" />
      {{ type === 'change' ? '请先将设备邮寄回，我们会尽快给您邮寄新设' : '请先将设备邮寄回，我们会尽快给您退换押金' }}      
    </div>
    <div v-preventReClick @click="submit()" class="submit-btn">{{ type === 'change' ? '更换设备' : '设备退租' }}</div>
    <div class="h85"></div>
    <input type="file" id="fileInput" multiple accept="image/*" v-show="false">
  </div>  
</template>

<script>
import { Icon, RadioGroup, Radio, DatetimePicker, Popup, Picker,Field, NumberKeyboard, CellGroup,Button } from 'vant';
import { uploadFile, orderChange, orderReturn, rentConfig, lastAddr } from '@/api/rent.js'
import { isEmpty, split, forEach } from 'lodash';
export default {
  components: {[Icon.name]: Icon, [Field.name]: Field},
  data() {
    return {      
      imgList:[],
      activeIndex: 1,
      value1: '',
      message: '',
      checked: true,
      type: this.$route.query.type, // change: 更换设备 ；其他退租
      form: {
        id: this.$route.query.id,
        phone: '',
        consignee: '',
        deliveryAddr: '',
        returnExpressOrder: '', // 退换快递单号
        returnExpressName: '', // 退换快递名称
        returnReason: '', // 退换原因
        returnUploadPics: '', // 换租/退租上传的图片地址 英文,分割
        returnAddress: '', // 退货地址 TODO 接口获取
      },
      defaultReturnAddress: []
    }
  },
  created() {    
    // ToDo 初始化收货地址、及退租地址及退货联系人信息
    rentConfig({}).then(res => {
      console.log('exchange ->>>>', res)
      this.defaultReturnAddress = split(res.returnAddress, ',')
      this.form.returnAddress = res.returnAddress
    })
    lastAddr({}).then(res=> {
      console.log('lastAddr---->', res)
      if(!isEmpty(res)) {
        this.form.phone = res.phone
        this.form.consignee = res.consignee
        this.form.deliveryAddr = res.deliveryAddr
        console.log('lastAddr---->', this.form)
      }
    })
  },
  mounted() {
    const fileInput = document.getElementById('fileInput');
    const _this = this;
    fileInput.addEventListener('change', function() {
      
      if(!fileInput.value) return;
      if(this.files.length + _this.imgList.length > 3) {
        _this.$toast('最多上传3张图片');
        return;
      }
      let flag = true
      forEach(this.files, (v) => {
        if(v.size > (1024*1024*10)) {
          flag = false;
          _this.$toast('单个图片不能大于10M')
        }
      })

      if(flag) {
        forEach(this.files, (file) => {
          const formData = new FormData();
          formData.append('file', file); // 'file' 是后端接收文件的字段名          
          // ToDo 对接文件上传
          uploadFile(formData).then(res => {
            fileInput.value = '';
            console.log('upload->res', res)
            res && _this.imgList.push(res)
          })  
        })
      }          
    });
  },
  methods: {    
    rmImg(index) {
      this.imgList.splice(index, 1)
    },
    uploadImg() {
      return document.getElementById('fileInput').click()
    },
    submit() {
      let canSubmit = true;
      if(isEmpty(this.imgList)) {
        this.$toast('请上传图片');
        return false
      }
      if(!this.form.returnReason) {
        this.$toast('请描述原因');
        return false
      }
      if(!this.form.returnExpressName) {
        this.$toast('请输入快递公司名称');
        return false
      }
      if(!this.form.returnExpressOrder) {
        this.$toast('请输入快递单号');
        return false
      }

      if(this.type === 'change') {
        if(!this.form.consignee) {
          this.$toast('请填写收货人');
          return false
        }

        if(!this.form.deliveryAddr) {
          this.$toast('请填写收货详细地址');
          return false
        }
        
        var regExp = /^1[3456789]\d{9}$/;
        if(!regExp.test(this.form.phone)) {
          this.$toast('请填写正确的手机号');
          return false
        }
      }
      // 提交
      this.form.returnUploadPics = this.imgList.join(',');
      if (this.type==='change') {
        orderChange({...this.form}).then(res => {
          console.log('orderChange ===> ',res);
          this.$toast('提交成功');
          this.$router.push({path: '/rent/detail', query: { id: this.form.id }});
        })
      } else {
        orderReturn({
          id: this.form.id,   
          returnExpressOrder: this.form.returnExpressOrder, // 退换快递单号
          returnExpressName: this.form.returnExpressName, // 退换快递名称
          returnReason: this.form.returnReason, // 退换原因
          returnUploadPics: this.form.returnUploadPics, // 换租/退租上传的图片地址 英文,分割
          returnAddress: this.form.returnAddress
        }).then(res => {
          console.log('orderChange ===> ',res);
          this.$toast('提交成功');
          this.$router.push({path: '/rent/detail', query: { id: this.form.id }});
        })
      }      
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vh;
  background: #FFF;
  overflow: auto;
  .submit-btn {
    margin:.58rem auto 0;
    width: 4.17rem;
    height: .77rem;
    background: #2B67D9;
    box-shadow: 0px .04rem .15rem -.08rem rgba(57,79,154,0.3);
    border-radius: .44rem;
    font-weight: 600;
    font-size: .27rem;
    color: #FFFFFF;
    line-height: .77rem;
    text-align: center;
  }
  .del-img {
    color: #FF0000;
    font-size: .3rem;
    position: absolute;
    top: .1rem;
    right: .1rem;
    zoom: 1.1;
  }
  .danger-tip {
    margin:auto;
    display: flex;
    align-items: center;
    margin-top: .2rem;
    width: 6.73rem;
    height: .79rem;
    background: rgba(254,0,0,0.08);
    border-radius: .08rem;
    font-weight: 400;
    font-size: .23rem;
    color: #FF0000;
    font-style: normal;
    img {
      margin-left: .17rem;
    }
  }
  .user-info {
    padding: 0 .38rem 0;
    font-size: .27rem;
    .u-label {
      color: #999;
      display: inline-block;
      min-width: fit-content;
    }
    .user-info-line-1 {
      display: flex;
      gap: .54rem;
      height: .96rem;
      align-items: center;
      .user-info-line-1-1 {
        display: flex;
        height: .96rem;
        align-items: center;
        border-bottom: .02rem solid #EEEEEE; 
      }
    }
    .user-info-line-2 {
      display: flex;
      gap: .0rem;
      height: .96rem;
      align-items: center;
      border-bottom: .02rem solid #EEEEEE; 
    }
  }
  .express-info {
    padding: 0 .38rem;
    display: flex;
    justify-content: space-between;
    .express-info-cell {
      /deep/.van-cell {
        padding: .1rem .16rem;
      }
      border: .02rem solid #000;
      border-radius: .08rem;
      height: .65rem;
    }
  }
  .express-tip {
    padding: .23rem .38rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: .27rem;
    color: #666666;
    line-height: .38rem;
    text-align: left;
    font-style: normal;
  }
  .q-desc {
    width: 6.72rem;
    margin: .3rem auto 0;    
    border-bottom: .02rem solid #EEE;
    font-size: .27rem;
    /deep/.van-cell {
      padding: 0;
      textarea {
        font-size: .27rem;
        // height: .48rem !important;
      }
    }
    /deep/.van-field__label {
      margin-right: 0;
      width:1.36rem;
      font-size: .27rem;
      color: #999;
    }
  }
  .img-list {
    padding: .15rem 0 0 .38rem;
    display: flex;
    gap: .07rem;
    flex-wrap: wrap;
    .img-list-item {
      width: 1.64rem;
      height: 1.21rem;
      position: relative;
      img {
        width: 1.64rem;
        height: 1.21rem;
      }
    }
    .img-up-btn {
      width: 1.61rem;
      height: 1.21rem;
      border-radius: .08rem;
      border: .02rem solid #2B67D9;      
      img {
        display: block;
        margin: .4rem auto 0;
        width: .44rem;
        height: .42rem;
      }
    }
  }
  .sub-title {
    padding: .25rem 0 0 .38rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: .31rem;
    color: #000000;    
    font-style: normal;
    display: flex;
    align-items: center;
    .span-red {
      color: #FF0000;
      font-size: .23rem;
    }
    .sub-title-tip {
      display: inline-block;
      margin-left: .3rem;
      width: .96rem;
      height: .42rem;
      border-radius: .21rem;
      border: .02rem solid #EEEEEE;
      text-align: center;
      font-weight: 400;
      font-size: .23rem;
      color: #000000;
      line-height: .42rem;
    }
  }
  .device-img {
    width: 7.5rem;
    height: 4.35rem;
  }
}
.h85 {
    height: .85rem;
  }
  
  .list-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    height: .85rem;
    padding: 0 .46rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF;
    .back-top-icon {
      width: .3rem;
      font-size: .32rem;
      color: #000;
      font-weight: bolder;
    }
    .header-title {
      font-size: .31rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
    .header-left {
      display: flex;
      align-items: center;
      width: auto;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 400;
      font-size: .27rem ;
      color: #2B67D9;
      .order-icon {
        width: .32rem;
        height: .32rem;
        margin-right: .08rem;
      }
    }
  }

</style>